import React from 'react';
import { categories } from '../utils/data';
import { graphql } from 'gatsby';
import theme from '../utils/theme';
import { ArrowBackIcon } from '@chakra-ui/icons'; 

import { ChakraProvider, Box, Flex, Heading, Link, Text, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import Banner from '../Sections/Banner';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Seo from '../components/SEO';

const QuestionTemplate = ({ pageContext }) => {
  const { t } = useTranslation('learn-with-us');
  const { slug } = pageContext;

  const question = categories
    .flatMap(category => category.questions)
    .find(q => q.slug === slug);

  if (!question) {
    return (
      <ChakraProvider theme={theme}>
        <Flex direction="column" w="100%">
          <Header />
          <Box id={slug} px={{ base: '20px', md: '80px' }} py={10} textAlign="center">
            <Container>
              <Box mb={4}>
                <Link href='/faqs' display="flex" alignItems="center">
                  <ArrowBackIcon mr={2} />
                  {t('learn-with-us.back')}
                </Link>
              </Box>
              <Box mb={10}>
                <Text size="md" fontWeight="500" fontSize="28px" mb={12}>
                {t('learn-with-us.not-found')}
                </Text>
              </Box>
            </Container>
          </Box>
          <Banner />
        </Flex>
        <Footer />
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column" w="100%">
        <Header />
        <Box id={slug} w='100%' px={{ base: '20px', md: '80px' }} py={10}>
          <Container>
            <Box mb={4}>
              <Link href='/faqs' display="flex" alignItems="center">
                <ArrowBackIcon mr={2} />
                {t('learn-with-us.back')}
              </Link>
            </Box>
            <Box mb={10}>
              <Heading as="h1" size="md" fontWeight="500" textAlign="center" fontSize="22px" mb={6}>
                {question.internal_title}
              </Heading>
            </Box>
            {question.video && (
              <Box mb={10} w={{ base: '100%', sm:'100%', md:'80%', lg: '60%' }} h={{ base: '220px', sm:'300px', md: '390px' }} mx="auto">
                  <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${question.video.split('v=')[1]}`}
                    title="Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
              </Box>
            )}
            <Box w={{ base: '100%', sm:'100%', md:'80%', lg: '60%' }} mx="auto" dangerouslySetInnerHTML={{ __html: question.answer }} className="content-html" />
            
            <Box w={{ base: '100%', sm:'100%', md:'80%', lg: '60%' }} mt="50px" mx="auto" className="content-html">
              <p style={{ marginBottom: '10px' }}>
                <span style={{ borderBottom: '0.05em solid' }}>Otros links de interés</span>
              </p>
              <ul>
                <li>
                  Visitá nuestras preguntas frecuentes: <a href="/faqs">Ir a Preguntas Frecuentes</a>
                </li>
                <li>
                  También podés visitar nuestra web: <a href="http://www.landtoken.io">landtoken.io</a>
                </li>
              </ul>
            </Box>
          </Container>
        </Box>
        <Banner />
      </Flex>
      <Footer />
    </ChakraProvider>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default QuestionTemplate;


export const Head = ({ pageContext }) => {
  const { slug } = pageContext;
  const question = categories
    .flatMap(category => category.questions)
    .find(q => q.slug === slug);

  return <Seo title={question ? question.internal_title : "Preguntas frecuentes"} />;
};